import type { PropsWithChildren, ReactNode } from 'react'
import React, { useContext } from 'react'
import { MainStylesVars, StyleSheet } from '../stylesheet'
import type { SocialMediaLinks } from '../../../grab/types'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import YoutubeChannelSvg from '../assets/socials/youtubeChannel.svg'
import InstagramSvg from '../assets/socials/instagram.svg'
import FacebookSvg from '../assets/socials/facebook.svg'
import SpotifySvg from '../assets/socials/spotify.svg'
import SoundcloudSvg from '../assets/socials/soundcloud.svg'
import LinkedinSvg from '../assets/socials/linkedin.svg'
import TwitterSvg from '../assets/socials/twitter.svg'
import WebsiteSvg from '../assets/socials/website.svg'
import ChapitoSvg from '../assets/socials/chapito.svg'
import ResidentAdvisorSvg from '../assets/socials/residentAdvisor.svg'
import TiktokSvg from '../assets/socials/tiktok.svg'
import BlueskySvg from '../assets/socials/bluesky.svg'
import TwitchSvg from '../assets/socials/twitch.svg'
import DeezerSvg from '../assets/socials/deezer.svg'
import BandcampSvg from '../assets/socials/bandcamp.svg'
import VimeoSvg from '../assets/socials/vimeo.svg'
import MyspaceSvg from '../assets/socials/myspace.svg'
import type { ButtonType } from 'antd/es/button'
import { Badge, Button } from 'antd'
import { getExternalMapLink } from './map-embed'
import type { AppPath } from '../navigation'
import { Navigation, RouteParamNames } from '../navigation'
import { keys } from '../../../services/typescript'
import type { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { faLocationArrow, faMapLocationDot } from '@fortawesome/free-solid-svg-icons'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'

const socialIconStyle = StyleSheet.create({ icon: { width: 32, height: 32 } }).icon
const socialIcons: Record<keyof SocialMediaLinks, React.ReactElement> = {
  youtubeVideoKey: <></>,
  youtubeChannel: <YoutubeChannelSvg style={socialIconStyle} />,
  instagram: <InstagramSvg style={socialIconStyle} />,
  facebook: <FacebookSvg style={socialIconStyle} />,
  spotify: <SpotifySvg style={socialIconStyle} />,
  soundcloud: <SoundcloudSvg style={socialIconStyle} />,
  linkedin: <LinkedinSvg style={socialIconStyle} />,
  twitter: <TwitterSvg style={socialIconStyle} />,
  website: <WebsiteSvg style={socialIconStyle} />,
  deezer: <DeezerSvg style={socialIconStyle} />,
  chapito: <ChapitoSvg style={socialIconStyle} />,
  residentAdvisor: <ResidentAdvisorSvg style={socialIconStyle} />,
  tiktok: <TiktokSvg style={socialIconStyle} />,
  bluesky: <BlueskySvg style={socialIconStyle} />,
  twitch: <TwitchSvg style={socialIconStyle} />,
  bandcamp: <BandcampSvg style={socialIconStyle} />,
  vimeo: <VimeoSvg style={socialIconStyle} />,
  myspace: <MyspaceSvg style={socialIconStyle} />,
}

export const Card: React.FC<PropsWithChildren<{ style?: React.CSSProperties }>> = ({ children, style }) => (
  <div style={{ ...styles.card, ...style }}>{children}</div>
)

function IconBadge({ children, size = 'large' }: { children: ReactNode; size?: 'small' | 'large' }): JSX.Element {
  return (
    <span
      style={{
        padding: size === 'large' ? 8 : 4,
        paddingInline: size === 'large' ? 15 : 8,
        fontSize: size === 'large' ? '1.2em' : '0.8em',
        borderRadius: 9999,
        backgroundColor: MainStylesVars.colorBlockActionLight,
        color: MainStylesVars.colorBlockAction,
        fontFamily: MainStylesVars.fontText,
      }}
    >
      {children}
    </span>
  )
}

export function BadgeList({ labels, size = 'large' }: { labels: string[]; size?: 'small' | 'large' }): JSX.Element {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 5,
        marginBottom: 5,
      }}
    >
      {[...new Set(labels)].map((l) => (
        <IconBadge key={l} size={size}>
          {l}
        </IconBadge>
      ))}
    </div>
  )
}

type LibIcon = IconProp
export const Icon: React.FC<{ style?: React.CSSProperties; icon: LibIcon; color?: string }> = ({ style, icon, color }) => (
  /* @ts-expect-error FontAwesomeIconStyle does not handle verticalAlign */
  <FontAwesomeIcon icon={icon} style={{ ...styles.icon, ...style, color: color ?? styles.icon.color }} />
)

export const RoundIcon: React.FC<{ icon: LibIcon; style?: React.CSSProperties }> = ({ icon, style }) => (
  <Icon style={{ ...styles.roundIcon, ...style }} color={style?.color ?? MainStylesVars.colorTitle} icon={icon} />
)

export const IconText: React.FC<{ icon: IconDefinition; value: string; href?: string | undefined }> = ({ icon, value, href }) => {
  return (
    <div>
      <RoundIcon icon={icon} style={styles.artistLinkIcon} />
      {href ? (
        <Button href={href} type="text" style={styles.artistLinkButton}>
          {value}
        </Button>
      ) : (
        <span style={styles.artistLinkButton}>{value}</span>
      )}
    </div>
  )
}

export const HorizontalBar: React.FC<{ style?: React.CSSProperties }> = ({ style }) => <div style={{ ...styles.horizontalBar, ...style }} />

export const SocialsList: React.FC<{ socials: SocialMediaLinks }> = ({ socials }) => {
  return (
    <div style={styles.socialsList}>
      {keys(socials)
        .filter((linkName) => linkName !== 'youtubeVideoKey')
        .filter((linkName) => !!socials[linkName])
        .map((linkName) => (
          <Button style={styles.socialLinkBtn} key={linkName} href={socials[linkName]} target="_blank">
            {socialIcons[linkName]}
          </Button>
        ))}
    </div>
  )
}

type IconBtnProps = {
  children: ReactNode | string
  icon?: LibIcon
  onClick?: () => void
  badge?: boolean
  link?: string
  type?: ButtonType
  style?: React.CSSProperties
  className?: string
}

export const IconBtn: React.FC<IconBtnProps> = ({ icon, children, onClick, badge, link, type, style, className }) => (
  <Button style={{ ...styles.iconButton, ...style }} onClick={onClick} type={type ?? 'primary'} href={link} target="_blank" className={className}>
    <Badge dot={badge} offset={[-7, 0]}>
      {icon && <Icon icon={icon} style={{ marginRight: 8, padding: 0 }} color={style?.color} />}
    </Badge>
    {children}
  </Button>
)

export const MapBtn: React.FC<{ position: { lng: number | string; lat: number | string }; locationName: string }> = ({ position, locationName }) => (
  <Button style={styles.mapBtn} href={getExternalMapLink(position)} target="_blank">
    <Icon icon={faMapLocationDot} style={{ width: 30, height: 30 }} color={MainStylesVars.colorBlockAction} />
    <div style={styles.mapBtnLocation}>{locationName}</div>
    <Icon style={styles.mapBtnIcon} color={MainStylesVars.colorBlockAction} icon={faLocationArrow} />
  </Button>
)

export const NavigationLink: React.FC<{ children: React.ReactElement; path?: AppPath; style?: React.CSSProperties }> = ({
  children,
  path,
  style,
}) => {
  const { setUrl, search } = useContext(Navigation)

  // keep all hash/search parameters but the ones affecting the route
  search.delete(RouteParamNames.guestRouteGuestId)
  search.delete(RouteParamNames.eventRouteEventId)
  if (path?.page === 'event') search.set(RouteParamNames.eventRouteEventId, path.eventId)
  else if (path?.page === 'guest') search.set(RouteParamNames.guestRouteGuestId, path.guestId)

  const targetUrl = `?${search.toString()}${window.location.hash}`

  const onClick: React.MouseEventHandler = (ev) => {
    ev.preventDefault()
    setUrl(targetUrl)
  }

  return path ? (
    // the href serves no functional purpose, it is there for accessibility
    <a href={targetUrl} onClick={onClick} style={{ textDecoration: 'none', color: 'inherit', ...style }} draggable={false}>
      {children}
    </a>
  ) : (
    children
  )
}

export function getNavigationPermalink(path: AppPath): string {
  return `${window.location.origin}${window.location.pathname}${window.location.hash}?${
    path.page === 'home'
      ? ''
      : path.page === 'event'
      ? `${RouteParamNames.eventRouteEventId}=${encodeURIComponent(path.eventId)}`
      : path.page === 'guest'
      ? `${RouteParamNames.guestRouteGuestId}=${encodeURIComponent(path.guestId)}`
      : ''
  }`
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: MainStylesVars.colorBlockBackground,
    borderRadius: 10,
    overflow: 'hidden',
    padding: 20,
  },
  icon: {
    verticalAlign: 'middle',
    padding: 5,
    color: MainStylesVars.colorBlockBackground,
    boxSizing: 'content-box',
    outline: 'none',
  },

  iconButton: {
    textTransform: 'none',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    color: MainStylesVars.colorBlockBackground,
  },

  roundIcon: {
    borderRadius: '50%',
    border: `1px solid ${MainStylesVars.colorTitle}`,
    marginRight: 8,
    marginTop: 4,
    marginBottom: 4,
  },

  verticalBar: {
    height: '100%',
    width: 4,
    backgroundColor: MainStylesVars.colorBlockAction,
  },

  horizontalBar: {
    height: 0.5,
    backgroundColor: MainStylesVars.colorBlockAction,
    marginTop: 15,
    marginBottom: 15,
  },

  socialsList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 12,
  },

  socialLinkBtn: {
    backgroundColor: 'white',
    padding: 8,
    height: 'auto',
    lineHeight: 0,
    border: 'none',
  },

  mapBtn: {
    display: 'flex',
    padding: 15,
    boxSizing: 'content-box',
    gap: 10,
    alignItems: 'center',
    borderColor: MainStylesVars.colorBlockAction,
    backgroundColor: 'transparent',
    color: MainStylesVars.colorBlockAction,
    textDecoration: 'none',
    fontSize: '.8em',
  },

  mapBtnLocation: {
    flexGrow: 1,
    textAlign: 'left',
    whiteSpace: 'break-spaces',
    textDecoration: 'none',
  },

  mapBtnIcon: {
    borderRadius: '50%',
    width: 20,
    height: 20,
  },

  artistLinkIcon: {
    color: MainStylesVars.colorBlockAction,
    borderColor: MainStylesVars.colorBlockAction,
  },

  artistLinkButton: {
    color: MainStylesVars.colorBlockAction,
    paddingLeft: 3,
    fontSize: 14,
  },
})
